import React from 'react';
import { FormattedMessage } from 'react-intl';

import antifraudIcon from './assets/images/antifraudIcon.svg';
import controlIcon from './assets/images/controlIcon.svg';
import internationalIcon from './assets/images/internationalIcon.svg';
import puntualIcon from './assets/images/puntual.svg';
import subscriptionsIcon from './assets/images/subscriptionsIcon.svg';
import tokenizationIcon from './assets/images/tokenizationIcon.svg';

import slantImg from './assets/images/slant.svg';

import './styles.scss';

const CardSpecs: React.FC = ({ children }) => (
  <>
  <section className="card-specs">
    <div className="py-layout-7 bg-light-gray-1-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <img src={antifraudIcon} alt="international payments img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.card.features.feature_1.title"/>
            </h4>
            <p>
              <FormattedMessage id="payments.card.features.feature_1.subtitle"/>
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <img src={puntualIcon} alt="antifraud img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.card.features.feature_2.title"/>
            </h4>
            <p>
              <FormattedMessage id="payments.card.features.feature_2.subtitle"/>
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <img src={internationalIcon} alt="integration img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.card.features.feature_3.title"/>
            </h4>
            <p>
              <FormattedMessage id="payments.card.features.feature_3.subtitle"/>
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <img src={controlIcon} alt="payment experience img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.card.features.feature_4.title"/>
            </h4>
            <p>
              <FormattedMessage id="payments.card.features.feature_4.subtitle"/>
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <img src={tokenizationIcon} alt="dashboard img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.card.features.feature_5.title"/>
            </h4>
            <p>
              <FormattedMessage id="payments.card.features.feature_5.subtitle"/>
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <img src={subscriptionsIcon} alt="subscriptions img" className="mb-4 mt-5 mt-md-0" />
            <h4 className="mb-4 text-primary">
              <FormattedMessage id="payments.card.features.feature_6.title"/>
            </h4>
            <p>
              <FormattedMessage id="payments.card.features.feature_6.subtitle"/>
            </p>
          </div>
        </div>
      </div>
    </div>

    <img className="card-specs__slant" src={slantImg} />
  </section>
  </>
);

export default CardSpecs;
