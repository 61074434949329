import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

export interface WalkthroughStep {
  description: string;
  image: any;
  order: number;
}

interface PaymentWalkThroughProps {
  steps: WalkthroughStep[];
}

const PaymentWalkthrough: React.FC<PaymentWalkThroughProps> = ({steps}) => (
    <div className="walkthrough-container__content">
      {
        steps
          .sort((a,b) => a.order - b.order )
          .map(step =>
            <div key={step.order} className="walkthrough-container__content__step">
              <div className="walkthrough-container__content__step__line" />
              <img src={step.image} className="img-fluid walkthrough-container__content__step__image" />
              <span className="text-dark-gray-3 mt-0 mt-md-4 walkthrough-container__content__step__description">
                <FormattedMessage id={step.description} />
              </span>
            </div>
          )
      }
    </div>
);

export default PaymentWalkthrough;
