import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';
import PaymentsAvailabilityTable from '../../PaymentsAvailabilityTable/PaymentsAvailabilityTable';

import './styles.scss';

import bgImg from './assets/images/bg-shape.svg';


const CardAvailability: React.FC = ({children}) => {
  const intl = useIntl();

  return (
    <section className="py-layout-7 card-availability-section overflow-hidden">
      <img src={bgImg} className="card-availability-section__bg"/>


      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h2 className="text-primary">
              <FormattedMessage id="payments.availability.title"/>
            </h2>
            <p className="text-dark-gray-3 mt-4" style={{whiteSpace: 'pre-wrap'}}>
              <FormattedMessage id="payments.availability.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
          <div className="col-lg-8 offset-lg-1">
            <PaymentsAvailabilityTable queryString={intl.formatMessage({id: 'payments.availability.cardQuery'})} />
          </div>
        </div>
      </div>

    </section>
  )
};

export default CardAvailability;
