import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import LocalizedLink from '../../../LocalizedLink/LocalizedLink';

import lessStepsImg from './assets/images/less-steps.svg';
import paymentExperience from './assets/images/payment-experience.svg';

import './styles.scss';

const CardFeatures: React.FC = ({ children }) => (
<div className="py-layout-5">
  <section className="py-layout-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h1 className="text-primary" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.card.benefits.benefit_1.title"
              values={defaultFormatValues}
            />
          </h1>
          <div className="line-separator--brand-green" />

          <p className="lead mt-5" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.card.benefits.benefit_1.subtitle"
              values={{
                ...defaultFormatValues,
                a: (...chunks: any) => (<LocalizedLink to="/features/cajita">{chunks}</LocalizedLink> ),
              }}
            />
          </p>
        </div>
        <div className="col-lg-6">
          <img src={paymentExperience} alt="Improved payment experience" className="img-fluid mt-layout-3 mt-lg-0"/>
        </div>
      </div>
    </div>
  </section>

  <section className="py-layout-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 order-2 order-lg-1 ">
          <img src={lessStepsImg} alt="Less Steps" className="img-fluid mt-layout-3 mt-lg-0"/>
        </div>
        <div className="col-lg-5 offset-lg-1 order-1 order-lg-2">
          <h1 className="text-primary" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.card.benefits.benefit_2.title"
              values={defaultFormatValues}
            />
          </h1>
          <div className="line-separator--brand-green" />
          <p className="lead mt-5" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payments.card.benefits.benefit_2.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
);

export default CardFeatures;
