import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import CardAvailability from '../../components/Payments/Card/CardAvaliablility/CardAvaliability';
import CardCTA from '../../components/Payments/Card/CardCTA/CardCTA';
import CardFeatures from '../../components/Payments/Card/CardFeatures/CardFeatures';
import CardHero from '../../components/Payments/Card/CardHero/CardHero';
import CardSpecs from '../../components/Payments/Card/CardSpecs/CardSpecs';
import CardWalkthrough from '../../components/Payments/Card/CardWalkthrough/CardWalkthrough';
import PaymentsCarousel from '../../components/Payments/PaymentsCarousel/PaymentsCarousel';
import { LocaleTypes } from '../../data/locales';


interface CardPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const CardPage: React.FC<CardPageProps> = ({ pathContext, location }) => {
  const content = () => (
    <>
      <SEO titleId="payments.card.title" descriptionId="payments.card.description" />

      <CardHero/>

      <CardFeatures />

      <CardSpecs />

      <CardWalkthrough />

      <CardCTA />

      <CardAvailability />

      <section className="py-layout-6 overflow-hidden">
        <div className="container">
          <div className="row mb-layout-4">
            <div className="col-12 text-center">
              <p className="lead">
                <FormattedMessage id="payments.carousel.title"/>
              </p>
            </div>
          </div>

          <PaymentsCarousel list={['transfer', 'cash', 'smartlink']} />
        </div>
      </section>

      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default CardPage;
