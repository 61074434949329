import React from 'react';
import { FormattedMessage } from 'react-intl';

import SalesButton from '../../../SalesButton/SalesButton';

import ctaImg from './assets/images/cta.svg';

import './styles.scss';

const CardCTA: React.FC = ({children}) => (
  <section className="section-xl bg-gradient-c">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-10 offset-1 offset-md-0 col-md-6">
          <img className="img-fluid" src={ctaImg} />
        </div>
        <div className="col-md-6">
          <h2 className="mt-7 mt-md-0 text-light">
            <FormattedMessage id="payments.card.cta.title"/>
          </h2>
          <div className="button-row mt-7">
            <SalesButton  big={true} theme="light" className="mt-2" positionTag="bottom">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CardCTA;
