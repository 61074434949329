import React, { useState } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { FormattedMessage } from 'react-intl';
import PaymentWalkthrough, { WalkthroughStep } from '../../PaymentsWalkthrough/PaymentWalkthrough';

import calendarImg from './assets/images/calendar.svg';
import formImg from './assets/images/form.svg';
import messageImg from './assets/images/message.svg';
import paymentImg from './assets/images/payment.svg';
import pcImg from './assets/images/pc.svg';
import validationImg from './assets/images/validation.svg';

import './styles.scss';

interface PaymentType {
  name: string;
  steps: WalkthroughStep[];
}

const paymentTypes: PaymentType[] = [
  {
    name: 'payments.card.walkthrough.walkthrough_1.tab_title',
    steps: [
      {
        description: 'payments.card.walkthrough.walkthrough_1.step_1',
        image: formImg,
        order: 1,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_1.step_2',
        image: validationImg,
        order: 2,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_1.step_3',
        image: paymentImg,
        order: 3,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_1.step_4',
        image: messageImg,
        order: 4,
      }
    ]
  },
  {
    name: 'payments.card.walkthrough.walkthrough_2.tab_title',
    steps: [
      {
        description: 'payments.card.walkthrough.walkthrough_2.step_1',
        image: formImg,
        order: 1,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_2.step_2',
        image: validationImg,
        order: 2,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_2.step_3',
        image: messageImg,
        order: 3,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_2.step_4',
        image: calendarImg,
        order: 4,
      }
    ]
  },
  {
    name: 'payments.card.walkthrough.walkthrough_3.tab_title',
    steps: [
      {
        description: 'payments.card.walkthrough.walkthrough_3.step_1',
        image: formImg,
        order: 1,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_3.step_2',
        image: validationImg,
        order: 2,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_3.step_3',
        image: messageImg,
        order: 3,
      },
      {
        description: 'payments.card.walkthrough.walkthrough_3.step_4',
        image: pcImg,
        order: 4,
      }
    ]
  }
]

const CardWalkthrough: React.FC = ({ children }) => {
  const [selectedPaymentType, setPaymentType] = useState(paymentTypes[0]);

  return (
    <>
      <Flipper flipKey={selectedPaymentType}>
      <section className="py-layout-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="text-primary">
                <FormattedMessage id="payments.card.walkthrough.title"/>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Flipped flipId="walthrough-content">
              <div className="walkthrough-container mt-layout-3">
                <Flipped inverseFlipId="walthrough-content" scale={true}>
                  <div className="payment-type-selector">
                    {
                      paymentTypes.map(paymentType =>
                        <div
                          className={`payment-type-selector__tab ${
                            paymentType === selectedPaymentType ?
                            'active' :
                            ''
                          }`}
                          key={paymentType.name}
                          onClick={() => setPaymentType(paymentType)}
                        >
                          <h4 className="text-primary mb-0"><FormattedMessage id={ paymentType.name }/></h4>
                        </div>
                      )
                    }
                  </div>
                </Flipped>
                <Flipped inverseFlipId="walthrough-content" scale={true}>
                  <PaymentWalkthrough steps={selectedPaymentType.steps} />
                </Flipped>
              </div>
              </Flipped>
            </div>
          </div>
        </div>

      </section>
      </Flipper>
    </>
  );
};

export default CardWalkthrough;
